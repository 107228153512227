import React from "react"
import AboutContent from "../constants/about";
import styled from "styled-components"


const About = () => {
    return (
        <ServicesContainer>
            <ServicesContent>
                <AboutContent/>
            </ServicesContent>
        </ServicesContainer>
    )
}

export default About;

const ServicesContainer = styled.div`
    padding: 5rem 1rem;
    background: ${props => props.theme.light};;
`;

const ServicesContent = styled.div`
    max-width: 1170px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 72px auto;
    
    @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    grid-template-columns: 1fr;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;