import React from "react"
import ServicesList from "../constants/services";
import styled from "styled-components"

const Services = () => {
  return (
      <ServicesContainer>
            <ServicesList/>
      </ServicesContainer>
  )
}

export default Services;

const ServicesContainer = styled.div`
    padding: 5rem 1rem;
    background: ${props => props.theme.white};;
`;

