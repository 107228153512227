import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../styles/Button";
import styled from "styled-components"

const Hero = () => {

    return (
        <HeroContainer>
            <HeroContent>
                <HeroItems>
                    <HeroH1>Bohabo Cornhole Žaidimas</HeroH1>
                    <HeroP>Cornhole nuoma, Cornhole lentos ir maišeliai. Rankų darbo Bohabo Cornhole žaidimas yra pagamintas ir ištobulintas Lietuvoje, kad atitiktų visus aukščiausius Amerikos PRO Cornhole turnyrų standartus.</HeroP>
                    <Button to="/kontaktai" big="true">Susisiekite su Mumis</Button>
                </HeroItems>
                <HeroImg>
                    <StaticImage src="../assets/images/hero-img.png" placeholder="none" quality="100" alt="Bohabo hero image"/>
                </HeroImg>
            </HeroContent>
        </HeroContainer>
    )
};

export default Hero

const HeroContainer = styled.div`
    position: relative;
    background: ${props => props.theme.white};
    padding: 9rem 1rem;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
        padding: 10rem 1rem;
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        padding: 10.75rem 1rem;
    }
`;

const HeroContent = styled.div`
     display: grid;
     grid-template-columns: repeat(12, 1fr);
     max-height: 100%;
     align-content: center;
     grid-row-gap: 1rem;
     max-width: 1170px;
     margin: 0 auto;
`;

const HeroItems = styled.div`
    text-align: center;
    grid-column: 1/13;
    align-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    text-align: left;
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
`;

const HeroH1 = styled.h1`
  color: ${props => props.theme.black};
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
  font-size: clamp(32px, 6vw, 64px);
  line-height: 120%;
  margin: 0 0 2.5rem 0;
  max-width: 90%;
  letter-spacing: -1px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 28px; 
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    margin: 0 auto 1rem auto;
  }
`;

const HeroP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 20px);
  max-width: 90%;
  margin: 0 auto 2.5rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    max-width: 65%;
    margin: 0 0 3.5rem;
  }
`;

const HeroImg = styled.div`
  grid-column: 1/13;
  transform: translate(-30px, 32px);
  max-width: 500px;
  justify-self: center;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    display: none;
  }
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 5/-1;
    transform: translate(16px, 60px);
    max-width: 100%;
  }
`;