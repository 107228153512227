import React from 'react';
import {HeadingH2, PageContent} from "../styles/GlobalStyles";
import {Underline} from "../styles/Underline";
import {Button} from "../styles/Button";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";

const AboutCornholeConstant = () => {

    return (
        <PageContent>

            <AboutItems>
                <HeadingH2>Apie Cornhole</HeadingH2>
                <Underline fluid primary small/>
                <AboutP><b>Cornhole</b> – ypač populiarus lauko žaidimas Jungtinėse Amerikos Valstijose. Trumpai apie žaidimą:
                    žaidimo lenta pastatoma 8 m. atstumu nuo ten kur bus metami specialūs žaidimui pritaikyti maišeliai.
                    Jeigu maišelis užkrenta ant lentos – skiriamas 1 taškas, jei įkrenta į skylę – 3 taškai, jeigu
                    maišelis nukrenta ant žemės arba nors kažkiek liečia žemę – 0 taškų. Žaidžiama iki 11 arba 21
                    taško.</AboutP>

                <Button to="/taisykles" big="true">Eiti į Taisykles</Button>
            </AboutItems>

            <AboutImg>
                    <StaticImage src="../assets/images/bohabo-bags.png" placeholder="none" quality="100" alt="Bohabo hero image"/>
            </AboutImg>

        </PageContent>
    );
};

export default AboutCornholeConstant;

const AboutItems = styled.div`
    grid-column: 1/-1;  
    text-align: center;
    align-self: center;
    justify-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-column: 7/13;
    text-align: left;
  }
`;

const AboutP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 18px);
  margin: 0 auto 2rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin: 4rem 0 5rem;
  }
`;

const AboutImg = styled.div`
  grid-column: 1/-1;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 1/7;
    transform: translateY(50px);
    max-width: 100%;
    padding-right: 5rem;
  }
`;