import React from "react"
import ContactContent from "../constants/contacts";
import styled from "styled-components"


const ContactUs = () => {
    return (
        <ServicesContainer>
            <ServicesContent>
                <ContactContent/>
            </ServicesContent>
        </ServicesContainer>
    )
}

export default ContactUs;

const ServicesContainer = styled.div`
    padding: 5rem 1rem;
    background: ${props => props.theme.white};
`;

const ServicesContent = styled.div`
    max-width: 1170px;
    margin: 72px auto;
`;