import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
import AboutCornhole from "../components/AboutCornhole"
import ContactUs from "../components/ContactUs";
import Seo from "../components/SEO"

const IndexPage = () => {
  return (
      <Layout>
          <Seo title="Bohabo Cornhole Žaidimas - Žaisk Cornhole Lietuvoje"
               description="Aukščiausios kokybės Bohabo Cornhole lentos ir maišeliai, atitinkantys visus ACL reikalavimus. Cornhole žaidimo įrangos nuoma Lietuvoje. Išbandykite!"
          />
              <Hero/>
              <AboutCornhole/>
              <Services/>
              <About/>
              <ContactUs/>
      </Layout>
  )
};

export default IndexPage



